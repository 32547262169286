import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import CategoryPage from './CategoryPage';

const App = () => {
    // State to hold categories and short stories
    const [categories, setCategories] = useState({});

    useEffect(() => {
        // Fetch the stories from the JSON file
        const fetchStories = async () => {
            try {
                const response = await fetch('stories.json'); // Adjust path if necessary
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setCategories(data.categories); // Set categories from JSON structure
            } catch (error) {
                console.error("Error fetching stories:", error);
            }
        };

        fetchStories();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <Router>
            <div>
                {/* Navigation Bar */}
                <Navbar categories={Object.keys(categories).map(category => ({
                    path: category,  // Assuming this is the transliterated version, adjust as needed
                    menuItemTitle: categories[category].menuItemTitle
                }))} />

                {/* Define Routes */}
                <Routes>
                    <Route path="/" element={<Home />} />
                    {Object.keys(categories).map((category) => (
                        <Route
                            key={category}
                            path={`/${category}`} // Direct use of category for routing
                            element={
                                <CategoryPage
                                    category={categories[category].menuItemTitle}
                                    stories={categories[category].stories}
                                />
                            }
                        />
                    ))}
                </Routes>

                {/* Footer */}
                <footer style={footerStyle}>
                    <p>&copy; 2024 СОРОМ. Контент 18+. Відверті історії українською</p>
                </footer>
            </div>
        </Router>
    );
};

const footerStyle = {
    textAlign: 'center',
    padding: '20px 0',
    backgroundColor: '#111',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
};

export default App;
