import React, { useState, useEffect } from 'react';

const CategoryPage = ({ category, stories }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const storiesPerPage = 2;

    // Reset currentPage to 1 when the category changes
    useEffect(() => {
        setCurrentPage(1);
    }, [category]);

    // Pagination logic
    const indexOfLastStory = currentPage * storiesPerPage;
    const indexOfFirstStory = indexOfLastStory - storiesPerPage;
    const currentStories = stories.slice(indexOfFirstStory, indexOfLastStory);

    const totalPages = Math.ceil(stories.length / storiesPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div style={pageStyle}>
            <h2 style={categoryTitleStyle}>{category}</h2>

            {currentStories.map((story, index) => (
                <div key={index} style={storyStyle}>
                    <h3 style={storyTitleStyle}>{story.title}</h3>
                    {story.content.split('\n').map((line, idx) => (
                        <p key={idx} style={storyContentStyle}>
                            {line}
                        </p>
                    ))}
                </div>
            ))}

            {/* Pagination controls */}
            <div style={paginationStyle}>
                <button onClick={handlePreviousPage} disabled={currentPage === 1} style={buttonStyle}>
                    Previous
                </button>
                <span style={pageIndicatorStyle}>
                    Page {currentPage} of {totalPages}
                </span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages} style={buttonStyle}>
                    Next
                </button>
            </div>
        </div>
    );
};

// Styles
const pageStyle = {
    padding: '100px 20px',
    color: '#fff',
    backgroundColor: '#1c1c1c',
};

const categoryTitleStyle = {
    fontSize: '32px',
    textAlign: 'center',
    marginBottom: '40px',
    fontFamily: "'Playfair Display', serif",
    color: '#f0c040',
};

const storyStyle = {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#2a2a2a',
    borderRadius: '8px',
};

const storyTitleStyle = {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#e0e0e0',
    fontFamily: "'Playfair Display', serif",
};

const storyContentStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#bfbfbf',
    fontFamily: "'Merriweather', serif",
};

const paginationStyle = {
    textAlign: 'center',
    marginTop: '20px',
};

const buttonStyle = {
    backgroundColor: '#f0c040',
    color: '#1c1c1c',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    margin: '0 10px',
};

const pageIndicatorStyle = {
    fontSize: '16px',
    color: '#fff',
};

export default CategoryPage;
