import React from 'react';

const Home = () => {
    return (
        <div style={pageStyle}>
            <h2 style={categoryTitleStyle}>СОРОМ</h2>
            <div style={storyStyle}>
                <h3 style={storyTitleStyle}>Дорослі історії українською</h3>
                <p style={storyContentStyle}>Тут ти знайдеш щось таке, що пробудить твої потаємні фантазії</p>
            </div>
            <div style={storyStyle}>
                <h3 style={storyTitleStyle}><a style={linkStyle}
                    href="mailto:the.sorom@gmail.com">
                    Напишіть нам the.sorom@gmail.com
                </a></h3>
            </div>
        </div>
    );
};


// Styles
const linkStyle = {
    color: '#ff6f61', // Use the same accent color as the category names
    textDecoration: 'none', // Remove underline
    fontWeight: 'bold', // Make it bold
    transition: 'color 0.3s ease', // Smooth color transition
};

const pageStyle = {
    padding: '100px 20px',
    color: '#fff',
    backgroundColor: '#1c1c1c',
};

const categoryTitleStyle = {
    fontSize: '32px',
    textAlign: 'center',
    marginBottom: '40px',
    fontFamily: "'Playfair Display', serif",
    color: '#f0c040',
};

const storyStyle = {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#2a2a2a',
    borderRadius: '8px',
};

const storyTitleStyle = {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#e0e0e0',
    fontFamily: "'Playfair Display', serif",
};

const storyContentStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#bfbfbf',
    fontFamily: "'Merriweather', serif",
};
export default Home;
