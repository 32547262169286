import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ categories }) => {
    return (
        <nav style={navStyle}>
            <ul style={ulStyle}>
                {/* Home link */}
                <li style={liStyle}>
                    <Link to="/" style={homeLinkStyle}>
                        Сором
                    </Link>
                </li>
                {categories.map((category) => (
                    <li key={category.path} style={liStyle}>
                        <Link to={`/${category.path}`} style={linkStyle}>
                            {category.menuItemTitle}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

// Styles for the navbar
const navStyle = {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#111',
    padding: '15px 0',
    zIndex: 1000,
    fontFamily: "'Playfair Display', serif",
};

const ulStyle = {
    display: 'flex',
    justifyContent: 'center',
    listStyle: 'none',
    margin: 0,
    padding: 0,
};

const liStyle = {
    margin: '0 30px',
};

const homeLinkStyle = {
    color: '#f0c040', // Gold color for links
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '18px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
};

const linkStyle = {
    color: '#f0c040', // Gold color for links
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '18px',
    textTransform: 'uppercase',
};

export default Navbar;
